export const governanceContracts = {
  mainnet: {
    token: "0x236501327e701692a281934230AF0b6BE8Df3353",
    devRewardDistributor: "0x6081d7F04a8c31e929f25152d4ad37c83638C62b",
  },
  // fuji: {
  //   token: "0x236501327e701692a281934230AF0b6BE8Df3353",
  //   devRewardDistributor: "0x9Fe90893E9F8Bb5F7772B7f422d04709020A9BFE",
  // },
};
